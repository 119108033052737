import { Timezone, UserType } from "../types.generated";
import {
  LANG_CODE_TO_ENGLISH_NAME,
  LANG_CODE_TO_LOCAL_NAME,
} from "@utils/strings";

export const timezoneCopy: { [key in Timezone]: string } = {
  [Timezone.UsEastern]: "US/ET",
  [Timezone.UsCentral]: "US/CT",
  [Timezone.UsMountain]: "US/MT",
  [Timezone.UsPacific]: "US/PT",
  [Timezone.UsArizona]: "US/MST",
};

export const timezoneChoices = Object.entries(timezoneCopy).map(([k, v]) => ({
  value: k as Timezone,
  label: v,
}));

export const userTypeCopy: { [key in UserType]: string } = {
  [UserType.Admin]: "Admin",
  [UserType.Manager]: "Manager",
  [UserType.Employee]: "Trainee",
};

export const ROLE_USER_TYPE_COPY: { [key in UserType]: string } = {
  [UserType.Admin]: "Admin",
  [UserType.Manager]: "Location Manager",
  [UserType.Employee]: "Trainee",
};

export const ROLE_USER_TYPE_DESCRIPTION: { [key in UserType]: string } = {
  [UserType.Admin]: "Someone who manages Opus",
  [UserType.Manager]: "Someone who manages specific location(s)",
  [UserType.Employee]: "Can complete training and find resources",
};

export const langCodeToEnglishName = (
  langCode: Language,
): string | undefined => {
  return LANG_CODE_TO_ENGLISH_NAME[langCode];
};

export const langCodeToLocalName = (langCode: Language): string | undefined => {
  return LANG_CODE_TO_LOCAL_NAME[langCode];
};
